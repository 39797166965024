import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наши услуги | Наши свадебные услуги в Eternal Vows
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Наши услуги | Наши свадебные услуги в Eternal Vows"} />
			<link rel={"shortcut icon"} href={"https://ploofix.com/img/434634622.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ploofix.com/img/434634622.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ploofix.com/img/434634622.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ploofix.com/img/434634622.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ploofix.com/img/434634622.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ploofix.com/img/434634622.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ploofix.com/img/434634622.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-5">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Наши услуги
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 280px 0px 280px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Компания Eternal Vows предлагает широкий спектр услуг, учитывающих все аспекты вашего свадебного путешествия. От индивидуального планирования до организации дня свадьбы - наш набор услуг гарантирует, что ваше торжество будет таким же уникальным и незабываемым, как ваша история любви.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 70px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Image
						src="https://ploofix.com/img/7.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						object-fit="cover"
						sm-height="220px"
						height="600px"
						lg-height="400px"
						md-width="100%"
						md-height="450px"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3">
					Планирование и координация
					</Text>
					<Hr
						min-height="10px"
						margin="0px 0px 10px 0px"
						border-color="--color-darkL1"
						width="40px"
						border-width="2px 0 0 0"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
					Индивидуальное планирование свадьбы: Индивидуальные услуги по планированию в соответствии с вашим видением, обеспечивающие беспрепятственное путешествие от первоначальной концепции до окончательного исполнения.
					<br/><br/>
Координация дня: Точность в каждой детали в ваш большой день, управляемая нашими опытными координаторами для гладкого и свободного от стресса празднования.

					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Image
							src="https://ploofix.com/img/8.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3">
						Церемония и прием
						</Text>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Уникальные места проведения: Доступ к эксклюзивным и очаровательным местам, которые станут идеальным фоном для вашей церемонии и приема.
						<br/><br/>
Индивидуальный декор и стилизация: От цветочных композиций до освещения - наша творческая команда создаст атмосферу, отражающую ваш личный стиль и тематику.

						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Image
							src="https://ploofix.com/img/9.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3">
						Кулинарные эксперименты
						</Text>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Кейтеринг для гурманов: Кулинарный пир с учетом ваших предпочтений и диетических требований, приготовленный нашей командой талантливых поваров.
						<br/><br/>
Индивидуальный дизайн тортов: Изысканные свадебные торты и десерты, персонализированные, чтобы дополнить вашу тему и порадовать ваши вкусовые рецепторы.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 30px 0px">
						<Image
							src="https://ploofix.com/img/10.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3">
						Развлечения и особые штрихи
						</Text>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Развлечения: Выбор музыкантов, диджеев и исполнителей для создания идеального настроения на вашем празднике.
						<br/><br/>
Индивидуальные детали: Индивидуальные приглашения, сувениры и уникальные элементы, которые добавят личный штрих к вашему свадебному дню.

						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Image
							src="https://ploofix.com/img/11.jpg"
							display="block"
							max-width="100%"
							margin="0px 0px 25px 0px"
							height="300px"
							width="100%"
							object-fit="cover"
							sm-height="220px"
							lg-height="200px"
							md-height="350px"
						/>
						<Text margin="0px 0px 15px 0px" font="--headline3">
						Фотография и видеосъемка
						</Text>
						<Hr
							min-height="10px"
							margin="0px 0px 10px 0px"
							border-color="--color-darkL1"
							width="40px"
							border-width="2px 0 0 0"
						/>
						<Text margin="0px 0px 15px 0px" font="normal 400 20px/1.2 --fontFamily-sans">
						Запечатление моментов: Сотрудничество с известными фотографами и видеографами, чтобы запечатлеть ваш день в стиле, который действительно отражает вашу сущность.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			Выберите Eternal Vows для своего торжества
			<br/><br/>
Начните свое свадебное путешествие с Eternal Vows, где каждый элемент является выражением вашей истории любви. С нашей командой экспертов рядом с вами мы приглашаем вас создать праздник, который выйдет за рамки обыденности и создаст гобелен воспоминаний, которые будут бережно храниться всю жизнь.

			</Text>
			<Button
				font="--lead"
				margin="20px"
				border-radius="8px"
				type="link"
				href="/contact-us"
				text-decoration-line="initial"
			>
				Связаться
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652e9dee47aa25001fb0714b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});